//This is the Admin Registration Setup Page.  It is used by admins to
//set up the type of league, event, or activity that users will register for.
//Whatever is added to this page will show up as an option for the users
//on the 'Register' page.
import React, { useEffect, useState } from 'react'
import './AdminRegistration.css'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import emailjs from 'emailjs-com'
import AdminButton from '../../shared/components/UIElements/AdminButton'
import AdminNavigation from './AdminNavigation'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import Modal from '../../shared/components/UIElements/Modal'
import { useHttpClient } from '../../shared/hooks/http-hook'

const AdminSuspendedPlayers = () => {
	const { sendRequest, clearError } = useHttpClient()
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [playerDeleted, setPlayerDeleted] = useState()
	const [loadedSuspendedPlayers, setLoadedSuspendedPlayers] = useState()
	const [playerToDelete, setPlayerToDelete] = useState()
	const [playerId, setPlayerId] = useState('')
	//
	const [suspensionId, setSuspensionId] = useState()
	const [showConfirmMoveToArchiveModal, setShowConfirmMoveToArchiveModal] =
		useState(false)
	const [suspensionToArchive, setSuspensionToArchive] = useState()

	//First, lets tap into the following route on the backend to get all CURRENT
	//the suspended players
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			setPlayerDeleted(false)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + `/admin/allSuspendedPlayers`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedSuspendedPlayers(responseData.allSuspendedPlayers)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setSuspensionToArchive(false)
			setIsLoading(false)
		}
		sendRequest()
	}, [setLoadedSuspendedPlayers, playerDeleted, suspensionToArchive])
	//
	//
	//
	//
	//
	//
	let navigate = useNavigate()
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	//
	//
	//
	//******************************************************** */
	//
	//     Delete warning
	//
	//******************************************************** */
	const showDeleteWarningHandler = (e, playername) => {
		const value = e.target.id
		setPlayerToDelete(playername)
		setPlayerId(loadedSuspendedPlayers[value]._id)
		setShowConfirmModal(true)
	}

	const cancelDeleteHandler = () => {
		setShowConfirmModal(false)
	}

	//console.log('league right here: ' + leagueId)
	const confirmDeleteHandler = async () => {
		setShowConfirmModal(false)
		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/admin/${playerId}/removeSuspension`,
				'DELETE'
			)
		} catch (err) {}
		setPlayerDeleted(true)
	}
	//
	//
	//
	//
	//******************************************************** */
	//
	//     Suspension 'Move to archive' warning
	//
	//******************************************************** */
	const showMoveToArchiveHandler = (e) => {
		const value = e.target.id
		console.log(loadedSuspendedPlayers[value]._id)
		setSuspensionId(loadedSuspendedPlayers[value]._id)
		setShowConfirmMoveToArchiveModal(true)
	}

	const cancelMoveToArchiveHandler = () => {
		setShowConfirmMoveToArchiveModal(false)
	}

	const confirmMoveToArchiveHandler = async () => {
		setShowConfirmMoveToArchiveModal(false)
		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/admin/${suspensionId}/archiveCurrentToggleSuspension`,
				'PATCH'
			)
		} catch (err) {}
		setSuspensionToArchive(true)
	}
	//
	//
	//
	//
	//
	//
	return (
		<React.Fragment>
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			<Modal
				show={showConfirmModal}
				onCancel={cancelDeleteHandler}
				header='Are you sure?'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<p className='modalText'>
							Are you sure you wish to delete <h1>{playerToDelete}</h1>
						</p>
						<br></br>
						<button
							className='cancelDeleteButtons buttonButton'
							inverse
							style={{ float: 'right' }}
							onClick={cancelDeleteHandler}
						>
							CANCEL
						</button>
						<button
							className='cancelDeleteButtons buttonButton'
							style={{ color: 'red' }}
							danger
							onClick={confirmDeleteHandler}
						>
							DELETE SUSPENSION
						</button>
					</React.Fragment>
				}
			></Modal>
			<Modal
				show={showConfirmMoveToArchiveModal}
				onCancel={cancelMoveToArchiveHandler}
				header='Are you sure?'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<p className='modalText'>
							Are you sure you wish to Archive this suspension?
						</p>
						<br></br>
						<button
							className='cancelDeleteButtons buttonButton'
							inverse
							style={{ float: 'right' }}
							onClick={cancelMoveToArchiveHandler}
						>
							CANCEL
						</button>
						<button
							className='cancelDeleteButtons buttonButton'
							danger
							onClick={confirmMoveToArchiveHandler}
						>
							ARCHIVE
						</button>
					</React.Fragment>
				}
			></Modal>
			{!isLoading && (
				<div className='RegLeagues'>
					<AdminNavigation />
					<h1>Suspended Players</h1>
					<div className='row'>
						<NavLink
							to={`/admin/oldSuspensions`}
							exact
							className='centeredButton'
							style={{ display: 'block', width: 'auto' }}
							/* style={{
								width: 'auto',
								display: 'block',
								color: 'white',
								borderRadius: '4px',
								background: '#92562e',
								textDecoration: 'none',
								padding: '10px',
							}} */
						>
							View Archived Suspensions
						</NavLink>
					</div>
					<table id='regLeagues'>
						<thead>
							<tr>
								<th>Date</th>
								<th>Player</th>
								<th>Team</th>
								<th>Reason</th>
								<th># of games</th>
								<th>Served?</th>
								<th>Appealed Successfully?</th>
								<th></th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{loadedSuspendedPlayers &&
								loadedSuspendedPlayers.map((suspension, key) => {
									return (
										<tr key={key}>
											<td>{suspension.date}</td>
											<td>{suspension.playerName}</td>
											<td>{suspension.playerTeam}</td>
											<td>{suspension.reason}</td>
											<td style={{ textAlign: 'center' }}>
												{suspension.howManyGames}
											</td>
											{suspension.served && <td>YES</td>}
											{!suspension.served && <td>NO</td>}
											{suspension.appealedSuccessfully && <td>YES</td>}
											{!suspension.appealedSuccessfully && <td>NO</td>}
											<td>
												<NavLink
													className='navlinks'
													to={`/admin/${suspension._id}/updateSuspension`}
													exact
												>
													Update
												</NavLink>
											</td>
											<td>
												<NavLink
													className='navlinks'
													id={key}
													onClick={(e) => showMoveToArchiveHandler(e)}
												>
													ARCHIVE
												</NavLink>
											</td>
											<td>
												<button
													className='buttonButton'
													style={{ color: 'red' }}
													id={key}
													onClick={(e) =>
														showDeleteWarningHandler(e, suspension.playerName)
													}
												>
													DELETE
												</button>
											</td>
										</tr>
									)
								})}
						</tbody>
					</table>
					<div
						style={{ width: '150%', marginTop: '1rem', marginBottom: '4rem' }}
					>
						<AdminButton
							to={`/admin/suspension/new`}
							exact
							style={{
								color: 'white',
								textDecoration: 'none',
							}}
						>
							NEW SUSPENSION
						</AdminButton>
					</div>
				</div>
			)}
		</React.Fragment>
	)
}

export default AdminSuspendedPlayers
